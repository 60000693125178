// 图文导航
.graphic-wrap{
    padding: 60px 0 60px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .graphic-card-module{
        width: 1200px;
        display: flex;
        // justify-content: space-between;
        margin-top: 12px;
        flex-wrap: wrap;
        .graphic-card-item{
            width: 280px;
            height: 450px;
            // box-shadow: 0px 12px 12px 0px rgba(131,137,163,0.12);
            // border: 1px solid #c5d8ff;
            border-radius: 10px;
            margin-top: 20px;
            margin-right: 26px;
            overflow: hidden;
            transform: translateY(0px);
            -webkit-transform: translateY(0px);
            cursor: pointer;
            .icon-wrap{
                width: 100%;
                height: 450px;
            }
            // .card-item-title{
            //     font-size: 26px;
            //     font-weight: bold;
            //     color: #343537;
            //     line-height: 1;
            //     margin-bottom: 30px;
            // }
            // .card-item-desc{
            //     font-size: 17px;
            //     color: #626879;
            //     line-height: 30px;
            // }
        }
        .graphic-card-item:hover{
            border-color: transparent;
            box-shadow: 0px 0px 20px 0px rgba(55,91,187,0.10); 
            transform: translateY(-3px);  //鼠标经过时，卡片向上移动3px，时间0.2s
            -webkit-transform: translateY(-3px);
            transition-delay: 0.2s;
        }
       
        // .graphic-card-item:nth-of-type(4n){
        //     margin-right: 0;
        // }
        .graphic-card-item-line-style-3{ //一行3个
            margin-right: 180px;
        }
        .graphic-card-item-line-style-3:nth-of-type(3n){
            margin-right: 0;
        }
        .graphic-card-item-line-style-3.teacher-info-desc{
            margin-right: 0;
        }
        .graphic-card-item-line-style-4{ //一行4个
            margin-right: 26px;
        }
        .graphic-card-item-line-style-4:nth-of-type(4n){
            margin-right: 0;
        }
        .graphic-card-item-line-style-4:last-of-type{
            margin-right: 0;
        }
    }
    .graphic-card-module-style-3{ //一行三个
        // width: calc(280px *3 + 30px *2);
    }
    // 图文导航样式2
    .graphic-swiper-warp{
        display: flex;
        flex-direction: row;
        width: 1200px;
        margin-top: 32px;
        height: 500px;
        .graphic-swiper-left-tab-warp{
            display: flex;
            flex-direction: column;
            width: 420px;
            height: 500px;
            justify-content: space-between;
            margin-right: 10px;
            .swiper-tab-item-box{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 38px;
                cursor: pointer;
                .item-content-title{
                    font-size: 20px;
                    line-height: 23px;
                    color: #343537;
                    margin-bottom: 0px !important;
                }
                .item-content-subtitle{
                    font-size: 16px;
                    color: #626879;
                    line-height: 19px;
                    padding-top: 8px;
                }
                .item-content-title ,.item-content-subtitle{
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;				//溢出内容隐藏
                    text-overflow: ellipsis;		//文本溢出部分用省略号表示
                    display: -webkit-box;			//特别显示模式
                    -webkit-line-clamp: 3;			//行数
                    line-clamp: 3;		
                    /*! autoprefixer: ignore next */   //pc端要加这句，不然没有省略号的样式出现			
                    -webkit-box-orient: vertical;	//盒子中内容竖直排列
                    padding-left: 9px;
                    border-left: 4px solid transparent;
                    word-break: break-all; //强制换行，避免纯数字的文本不会自动换行
                }
                .active-select{
                    .item-content-title ,.item-content-subtitle{
                        color: #396BF0!important;
                        border-left: 4px solid #396BF0;
                    }
                    .item-content-title{
                        font-weight: bold;
                    }
                }
            }
        }
        .graphic-swiper-right{
            width: 800px;
            .banner-img{
                width: 100%;
                height: 500px;
                cursor: pointer;
                border-radius: 10px;
            }
            
        }
    }
    // .graphic-line{
    //     background: #A4B6FF;
    //     width: 1200px;
    //     height: 1px;
    //     margin: 88px 0 100px;
    // }
    // .content-card-wrap{
    //     width: 1200px;
    //     display: flex;
    //     flex-direction: column;
    //     .content-card-item{
    //         width: 1200;
    //         height: 360px;
    //         background-size: 100% 100%;
    //         display: flex;
    //         flex-direction: column;
    //         .content-card-item-title,.content-card-item-desc,.content-card-item-btn-wrap{
    //             width: 630px;
    //         }
    //         .content-card-item-title{
    //             font-size: 26px;
    //             color: #343537;
    //             font-weight: bold;
    //             line-height: 1;
    //         }
    //         .content-card-item-desc{
    //             font-size: 18px;
    //             color: #626879;
    //             margin: 34px 0 40px;
    //             line-height: 1.5;
    //         }
    //         .content-card-item-btn-wrap{
    //             display: flex;
    //             justify-content: center;
    //             .content-card-item-btn{
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: center;
    //                 color: #fff;
    //                 width: 140px;
    //                 height: 40px;
    //                 opacity: 1;
    //                 font-size: 20px;
    //                 background: #396bf0;
    //                 border-radius: 20px;
    //                 cursor: pointer;
    //             }
    //         }
    //     }
    //     .content-card-item:first-of-type{
    //         margin-top: 100px;
    //         height: 456px;
    //     }
    //     .content-card-item:nth-of-type(2){
    //         align-items: flex-end;
    //     }
    // }
    
    
}
