.footer-wrap{
    height: 200px;
    background: url('../../assets/homePage/footer.png') no-repeat center center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .footer-text{
        text-align: center;
        color: #fff;
        font-size: 30px;
        line-height: 1;
        margin: 50px 0 35px;
    }
    .footer-btn{
        width: 180px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #fff;
        background: #FCDD40;
        border-radius: 23px;
        cursor: pointer;
    }
}