// 教师模块
.teacher-intro-wrap{
    padding: 60px 0 60px;
    background: #F5FAFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.teacher-intro-card-module{
    width: 1200px;
    display: flex;
    // justify-content: space-around;
    margin-top: 12px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  
    .teacher-info-card-item{
        width: 280px;
        height: 556px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // box-shadow: 0px 12px 12px 0px rgba(131,137,163,0.12);
        border: 1px solid #eaedf5;
        border-radius: 10px;
        background-color: #fff;
        overflow: hidden;

        margin-top: 20px;
        .teacher-info-img{
            width: 100%;
            height: 318px;
            // box-shadow: 0px 12px 12px 0px rgba(131,137,163,0.12);
        //    border-radius: 10px  ;

        }
        .teacher-info-card-content{
            width: 280px;
            height: 238px;
            padding: 12px 16px 0;
            p{
                margin-bottom: 12px;
            }
            .teacher-info-name{
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                color: #343537;
                // word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .teacher-info-desc{
                font-size: 16px;
                color: #626879;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 7;	
                /*! autoprefixer: ignore next */   //pc端要加这句，不然没有省略号的样式出现
                -webkit-box-orient: vertical;
                // -webkit-text-fill-color: transparent;
                word-break: break-all;
            }
        }
    }
    .teacher-info-item-line-style-3{ //一行3个
        margin-right: 180px;
    }
    .teacher-info-item-line-style-3:nth-of-type(3n){
        margin-right: 0;
    }
    .teacher-info-item-line-style-3.teacher-info-desc{
        margin-right: 0;
    }
    .teacher-info-item-line-style-4{ //一行4个
        margin-right: 26px;
    }
    .teacher-info-item-line-style-4:nth-of-type(4n){
        margin-right: 0;
    }
    .teacher-info-item-line-style-4:last-of-type{
        margin-right: 0;
    }
}
// .teacher-info-wrap-style-3{
//     width: calc(280px *3 + 30px *2);
// }
