.header_box{
    height:72px;
    background:#ffffff;
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0px;
    font-size: 14px;
}
.title_content{
    height:72px;
    display:flex;
    align-items:center;
    position:relative;
    width: 1176px;
    margin: 0 auto;
}
.title_right_box{
    color:#5F6266;
    /* font-size:16px; */
    position:absolute;
    right: 0px;
    display: flex;
    align-items: center;
}
/* 头像 */
.avatar-module{
    position: relative;
}
.avatarUrl{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
}
.avatar-btn-list{
    min-width: 124px;
    position: absolute;
    background: #ffffff;
    padding: 30px 20px;
    box-shadow: 0 0 10px 0 #B4B3B3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    left: -44px;
}
.avatar-btn-list div{
    line-height: 1;
    margin-bottom: 24px;
}
.avatar-btn-list div:last-child{
    margin-bottom: 0;
}
.user_tip{
    background-color: #F67123;
    color: #ffffff;
    width: 36px;
    height: 18px;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 13px;
}
/* APP下载 */
.APP-download{
    cursor: pointer;
    color: #ffc110;
    text-decoration: underline;
    margin-right: 20px;
}
.code_box{
    display: none
}
.show_code{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 32px;
    left: -32px;
    padding: 30px 20px 10px;
    /* border: 1px solid rgba(60,57,57,0.09); */
    box-shadow: 0 0 10px 0 #B4B3B3;
    border-radius: 4px;
    background: #fff;

}
/* tab */
.main-tab{
    display: flex;
}
.main-tab-item{
    width: 88px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #0F0F0F;
}
.active-tab{
    background: #FFC100;
}