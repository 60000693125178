// 蓝色主题
.blue-theme {
	// 班级学习选中
	.my-class .my-class-top-bar .active{
		background: #E8EEFE!important;
	  color: #396BF0!important;
	}
	// 课程列表左侧
	.left-border{
		background-color: #396BF0!important;
	}
	// 练习题库背景、字体颜色
	.topBg{
		.top-bg-txt-wrap{
			border-left: 4px solid #396BF0!important;
		}
	}
	.exercise-list-title-wrap{
		background: #396BF0!important;
	}
	// 题库模块题目类型鼠标移入样式
	.parent_exam_card:hover{
		.my_exam_card{
			transform: translate(0,-2px);
			border: 1px #396BF0 solid!important;
		}   
	}
	.exam_main_box{
		.exam-card-module-title{
			border-left: 4px solid #396BF0!important;
		}
	}
	// 新题库
	.title-btn{
		color: #fff!important;
		background: #396BF0!important;
	}
	.questions-type{
		color: #396BF0!important;
	}
	.option-checked{
		color: #fff!important;
		background: #396BF0!important;
		border-color: #396BF0!important;
	}
	.section-type-item:hover{
		border-color: #1E70FF!important;
		box-shadow: 0px 4px 12px 0px rgba(39, 83, 193, 0.12);
	}

	// 新课程-录播、直播、套餐
	.my-class .package .package-top-list .package-top-bar .active{ //套餐tab
		color: #396BF0 !important;
	}
	.new-section-item-status{ //视频状态标识
		color: #396BF0 !important;
		border: 1px solid #396BF0 !important;
	}
	.new-item-material-btn{ //资料按钮
		color: #396BF0 !important;
		border: 1px solid #396BF0 !important;
	}
	.new-live-item-status{
		color: #396BF0 !important;
		border: 1px solid #396BF0 !important;
	}
	.new_liveing_icon{
		 background-color: #3A86FF !important;  
	}
	.new_liveing{
		 color:  #396BF0 !important;
	}
	.Learning .ant-tabs-tab-active{
		color:  #396BF0 !important;
	}
	.Learning .ant-tabs-ink-bar{
		background-color: #3A86FF !important;  

	}
	.learn .learn_title span{
		background-color: #3A86FF !important;  

	}
	.Learning .ant-tabs-tab:hover{
		color:  #396BF0 !important;
	}
	.search-btn{
        background-color:#396BF0 !important;
        color:#f0ede3 !important;
    }
	.agreement-list-content-item-btn-wrap{
		div{
			color: #396BF0 !important;
			border: 1px solid #396BF0 !important;
		}
		div:nth-of-type(2){
			background: #396BF0 !important;
		}
	}
}