#live-player{
    background: #FFF;
    position: relative;
    .full-screen-btn{
        position: absolute;
        bottom: 4px;
        right: 18px;
        background: #313339;
        cursor: pointer;
        color: #bdc6cf;
        padding: 0 5px;
        border-radius: 3px;
        border-width: 1px;
        border: 1px solid transparent;
        width: 51px;
        height: 28px;
        box-sizing: content-box;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hover-mask{
        width: 100%;
        height: 100%;
        position: absolute;
        width: 61px;
        height: 28px;
        left: 0;
        top: 0;
        border-radius: 3px;
        border: 1px solid transparent;
    }
    .full-screen-btn:hover{
        color: #fff;
        // background: rgba(255,255,255,0.2);
        border-color: rgba(255,255,255,0.1);
        .hover-mask{
            background: rgba(255,255,255,0.2);
            border: 1px solid rgba(255,255,255,0.1);
        }
    }
}
.play-back-container{
    display: flex;
    #bjy-withboard-container{
        width: 840px;
        height: 100%;
        position: relative;
        flex-grow: 1;
    }
    // 控制条
    .bjy-playback-playslider-wrapper{
        transition: all 0.5s;
        padding: 0 20px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        // width: 840px;
        width: 100%;
        height: 50px;
        background-color: rgba(0,0,0,0.5);
        z-index: 11;
        bottom: -50px;
        bottom: -50px;
        .bjy-playback-playslider{
            width: 550px;
            // flex-grow: 1;
            .bjy-slider{
                flex-grow: 1;
            }
        }
        >*{
            margin-right: 30px;
        }
    }
    .play-back-right{
        width: 340px;
        height: 100%;
        display: flex;
        // flex-grow: 1;;
        flex-direction: column;
        #bjy-player-teacher{
            width: 100%;
        }
        #bjy-playback-wrapper{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #ddd;
            z-index: 9;
        }
        #bjy-playback-message-wrapper{
            width: 100%;
            flex-grow: 1;
            .bjy-content{
                background-color: rgb(249, 221, 74);
            }
        }
    }
    .bjy-playback-volume{
        position: absolute;
        bottom: 30px;
    }
    .whiteboard-fullscreen{
        // position: absolute!important;
        // width: 1162px!important;
        // height: 669px!important;
        // width: 100%;
        // height: 100%;
        width: auto!important;
        height: auto!important;
    }
}
#bjy-withboard-container:hover .bjy-playback-playslider-wrapper{
    bottom: 0;
}
// 倍速
.playback-rate-select{
    span{
        color: #fff;
    }
    .ant-select-selection{
        background: transparent;
        color: #fff;
    }
}
.playback-rate-select-option{
    color: #fff;
    background: rgba(0,0,0,0.5);
}
.ant-select-dropdown-menu-item-active{
    color: #1890ff;
}
.playback-rate-select-menu{
    bottom: 0!important;
    top: unset!important;
}
// 聊天记录
#bjy-playback-message-wrapper-container{
    position: relative;
    flex-grow: 1;
    margin-bottom: 50px;
}