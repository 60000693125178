.noFoundContent{
    height: 100%;
    position: relative;
    .noFoundBox{
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -190px;
        margin-left: -165px;
        width: 330px;
        text-align: center;
        img{
            width: 100%;            
        }
        p:nth-child(2){
            color: #3464ff;
            font-size: 24px;
            font-weight: blod;
            margin-bottom: 10px;
        }
        p:nth-child(3){
            color: #515a6e;
        }
    }

}