@import '~antd/dist/antd.css';
body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4!important;
  font-size: 16px!important;
}

#root{
  height: 100%;
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* 全局样式 */
.clearfix {
  zoom: 1;
}
.clearfix::after{
  content: "";
  clear: both;
  display: block;
  overflow: hidden;
  font-size: 0;
  height: 0;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.dn{
  display: none;
}
.db{
  display: block;
}

.color-black{
  color: #001529;
}

.color-red{
  color:#EF323B;
}

.colorBlue{
  color:#1890FF;
}

.colorGray{
  color: #76808C;
}

.colorBlue2{
  color: #3F64FD;
}

.color333{
  color: #333!important;
}

.color999{
  color: #999!important;
}
.fontB{
  font-weight: bold;
}

.tc{
  text-align: center;
}

.oh{
  overflow: hidden;
}

.cp{
  cursor: pointer;
}

.mr10{
  margin-right: 10px!important;
}
.mb10{
  margin-bottom: 10px!important;
}
.mb20{
  margin-bottom: 20px!important;
}
.mb30{
  margin-bottom: 30px!important;
}

.font14{
  font-size: 14px!important;
}

.font18{
  font-size: 18px!important;
}

.font20{
  font-size: 20px!important;
}


/* modal居中显示 */
.ant-modal-title{

}
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

/* radio样式垂直排列 */
.radioBlock{
  display: block!important;
  height: 30px;
  line-height: 30px!important;
}

/* 按钮样式 */
.ant-btn{
  // border-radius: 5px!important;
  // font-size: 12px!important;
  // line-height: 12px!important;
}
.btnCircle{
  border-radius: 16px!important;
}
.btnActive{
  border: 1px solid #3f64fd !important;
  color: black !important;
  font-weight: 600 !important;
  background: url("../src/assets/img/tick.png") no-repeat right bottom !important;  
  transition:none!important;
}

// 表格样式
.ant-table{
  font-size: 12px!important;
}
.ant-table-thead > tr > th{
  background: #E1E8F0!important;
  color: #001529!important;
  padding: 11px 8px!important;
}
.ant-table-tbody > tr{
  background: #fff!important;
}
.ant-table-tbody > tr > td{
  padding: 14px 8px!important;  
}
.ant-table-row-expand-icon-cell{
  width: 40px!important;
  min-width: 40px!important;  
  span{
    background: none!important;
    border: none!important;
  }
}
.ant-table-row-collapsed::after {
  content: '>'!important;
}
.ant-table-row-expanded{
  transform:rotate(90deg)!important;
}
.ant-table-row-expanded::after {  
  content: '>'!important;
}

.ant-table-expanded-row{
  &>td:last-child {
    padding: 30px 55px 30px 8px!important;
  }
  .ant-table-thead > tr > th{
    background: rgb(233, 243, 255)!important;
  }  
}

// 分页样式
.ant-pagination {
  text-align: right;
  margin-top: 20px!important;
}

//layout样式
.ant-layout-header{
  height: 72px!important;
  line-height: 18px!important;
  padding: 0px;
}

//antd框架组件字体大小
.ant-modal-title{
  font-size: 14px!important;
}
.ant-select,
.ant-select-dropdown,
.ant-form label,
.ant-form-item{
  font-size: 12px!important;
}

//全局类名样式
.inline_block{
  display: inline-block;
}
.card_div{  //边框阴影效果
  background:rgba(255,255,255,1);
  box-shadow:0px 5px 15px 0px rgba(0,21,41,0.08);    
  padding: 10px 20px 15px;
}
.clamp2{ //文本显示超出两行显示...
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
//播放器样式覆盖
.time_uz62bc {
  width: 110px !important;
}
.video-js .vjs-big-play-button{
  top: 50% !important;
  left: 50% !important;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}

.flex-cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.flex1{
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex-centerbox {
  /*水平居中*/
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  /*垂直居中*/
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-center-y {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-center-x {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}