.vedio_content_box{
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    .play-back-container{
        position: relative;
        overflow: hidden;
    }
    .fullscreen-video-wrap{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw!important;
        height: 100vh!important;
        z-index: 9;
        background: #000;
        #video-content{
            width: 100%;
            height: 100%;
        }
    }
    .bjy-playback-volume-bar{
        position: absolute;
        bottom: 39px;
        background: rgba(0,0,0,0.5);
    }
    .bjy-playback-volume{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100px;
        position: static;
        span{
            color: #fff;
        }
    }
    .play-back-container:hover .bjy-playback-playslider-wrapper{
        bottom: 0;
    }
    .bjy-playback-playslider{
        display: flex;
        flex-grow: 1;
        align-items: center;
        color: #fff;
        .ant-slider{
            flex-grow: 1;
        }
    }
    .left_box{
        width: 1070px;
        height: 100%;
        float: left;
    }
    .liveing_poliv{
        width: 1192px;
        height: 719px;
    }
    .right_box{
        width: 300px;
        height: 100%;
        float: right
    }
    .liveing_section_list{
        width: 342px;
        right: -342px;
        position: absolute;
        transition: all 0.3s;
    }
    .showCatalog{
        border-bottom: 1px solid rgb(217, 215, 215);
        background: #F9FBFE;
    }
    .is_show_icon{
        display: none;
    }
    .liveing_doubel_left{
        position: absolute;
        top: 45px;
        right: 0px;
        width: 30px;
        height: 127px;
        padding: 0px 6px;
        cursor: pointer;
        background: #F9FBFE;
        transition: all 0.3s;
        z-index: 10;
    }
    .transform_x{
        right: 342px;
    }
}
.liveing_content_box{  //直播
    overflow: hidden;
    // width: 1192px;
    height: 719px;
    .showCatalog{
        right: 0;
        width: 342px;
        border-bottom: 1px solid rgb(217, 215, 215);
        background: #F9FBFE;
        position: absolute;
        z-index: 10;
    }
}
.record_content_box{
    width: 1370px;
    height: 603px;
}
ul,li{
    list-style: none;
}
.chapter_list{
    height: 500px;
}
.liveing_chapter_list{
    height: 616px;
}
.chapter_list,.liveing_chapter_list{
    padding-left: 15px;
    font-size: 16px;
    color: #101010;
    overflow-y: auto;
    ul{
        padding: 0px;
    }
}
.liveing_chapter_list>li{
    padding-top: 29px; 
}
.chapter_list>li{
    padding-top: 29px; 
}
.section_li{
    padding-bottom: 23px;
    font-size: 14px;
    color: #5F6266;
}
.action_section{
    color: #E50012;
}
.section_li:hover{
    cursor: pointer;
    color: #E50012;
}
.class-list-player-line{
    display: inline;
    margin: 0 8px;
    width: 1px;
    height: 16px;
    background: #D6D8DB;
}
// 课程评价
// .star-bar{
//     .ant-rate-star{
//         margin-right: 17px;
//     }
// }
// .feedback-list{
//     width: 100%;
//     flex-wrap: wrap;
//     align-items: center;
//     .ant-checkbox-wrapper{
//         margin-bottom: 15px;
//         padding: 10px 20px;
//         border: 1px solid #E9E9E9;
//         border-radius: 6px;
//         line-height: 1;
//         margin-right: 20px;
//         .ant-checkbox{
//             display: none;
//         }
//     }
//     .ant-checkbox-wrapper-checked{
//         border-color: #FFC121;
//         background: #FFF0C9;
//     }
// }
.chapter_title{
    padding-bottom: 0px;
    margin-bottom: 10px;
}
.new_chapter_list >li{
    padding-top: 0px;
}