// 热门课程
.hot-course-wrap{
    padding: 30px 0 60px;
    background: #F5FAFF;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.hot-course-content-main{
    width: 1200px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    margin: 0 auto;
   
    .hot-course-content-item{
        width: 285px;
        height: 270px;
        margin-right: 20px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
        position: relative;
        cursor: pointer;
        .hot-course-content-img{
            width: 285px;
            height: 163px;
            background-size: 100% 100%;
            flex-shrink: 0;
        }
        .hot-course-content-text{
            padding: 16px 12px;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: space-between;
            .hot-course-content-title{
                line-height: 18px;
                font-size: 16px;
            }
            .hot-course-content-price{
                line-height: 1;
                font-size: 18px;
                color: #F84257;
                text-align: right;
            }
        }
    }
    
}