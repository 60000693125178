.modular_card_box{
    zoom: 1;
}
.modular_card_box::after{
    content: "";
    display: block;
    clear: both;
    visibility: hidden
}
.modular_content_box{
    
    span{
        display: inline-block;
        font-size: 25px;
        margin-right: 32px;
    }
    .tabs_tag{
        font-size: 16px;
        margin: 0 13px;
        display: inline-block;
    }
    .active{
        color: #fff;
        background: #F13232;
        padding: 4px 8px;
        border-radius: 4px;
    }
}
.tabs_tag:hover{
    color: #F13232;
    cursor: pointer;
}

.moduleHeader{
    img{
        margin-top: 6px;
    }
    p:last-child{
        color: #9e9e9e;
        margin-right: 12px;
    }
}

.exam_list_main_box{
    display: flex;
    flex-direction: row;
    justify-content: space-betweens;
    margin: 19px 0px;
    div{
        width: 33%;
        display: flex;
    }
    .left_text_box{
        justify-content: flex-start;
    }
    .center_text_box{
        justify-content: center;
        flex-grow: 1;
    }
    .right_text_box{
        justify-content: flex-end;
        button{
            cursor: pointer;
            width: 97px;
            height: 30px;
            border-radius: 15px;
            background: #FFC121;
            border: none;
            font-size: 13px;
            color: #333333;
        }
        .default_btn{
            color: #FFC121;
            border: 1px solid #FFC121;
            background: #FFFFFF;
        }
    }
}

.ant-collapse{
    background-color: #ffffff;
    border: none;
}