// 教师介绍
.video-intro-wrap{
    padding: 50px 0 50px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .video-player-box{
        width: 1200px;
        height: 675px;
        background: #D9D9D9;
        overflow: hidden;
        border-radius: 12px 12px 12px 12px;
        margin-top: 32px;
        .video-content{
            width: 100%;
            height: 100%;
        }
    }
    
    
}