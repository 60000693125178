.login-page{
  width: 1000px;
  height: 560px;
  background: white;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -280px 0 0 -500px;
  padding: 45px 61px 78px 77px;
  box-shadow:0px 17px 111px 20px rgba(131,122,122,0.09);

  .picImg{    
    position: absolute;
    top: 115px;
    right: 61px;
  }

  .titleBox{
    margin-top: 62px;
    span{
      display: inline-block;
      float: left;
    }

    .line{
      width:1px;
      height:20px;
      background:rgba(236,236,236,1);
      margin: 6px 20px 0;      
    }
  }

  .login-form{
    width: 320px;
    margin-top: 30px;
  }

  .getCodeBox{
    width: 110px;
    border:1px solid #C5CAD1;
    border-left: 1px solid #ECECEC;
    height: 40px;
    text-align: center;
    
  }

  .ant-input{
    border-radius: 2px;
  }

  .login-form-button{
    width:320px;
    height:50px;
    background:linear-gradient(142deg,rgba(246,44,61,0.96),rgba(255,88,55,0.96));
    border-radius:4px;    
    font-size: 16px!important;
    color: #fff;
  }  
}



.registerBox{

  width: 1200px;
  height: 550px;
  background: white;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -275px 0 0 -600px;
  box-shadow:0px 17px 111px 20px rgba(131,122,122,0.09);

  img{
    margin: 170px 12px 22px 505px;
  }

  .title{
    margin-top: 166px;
    font-size:34px;
    font-weight:500;
    color:rgba(33,34,58,1);    
    margin-bottom: 0;
  }

  .login-form-button{
    width:320px;
    height:50px;
    background:linear-gradient(142deg,rgba(246,44,61,0.96),rgba(255,88,55,0.96));
    border-radius:4px;    
    font-size: 16px!important;
    color: #fff;
  }  
}

.codeBox{
  width:159px;
  height:209px;
  background:rgba(255,255,255,1);
  box-shadow:0px 6px 23px 4px rgba(60,57,57,0.09);
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -105px;
  
  .imgBox{
    margin: 11px 8px 6px;
    padding: 3px;
    border:1px solid rgba(235,235,235,1);

    img{
      width: 136px;
      height: 136px;
    }
  }

  p{
    font-size: 14px;
    color:#a0a0a0;
    margin-bottom:2px ;
  }
}
.footer_btn{
  .study-btn{
    border-radius: 24px!important;
    font-size: 20px!important;
  }
}