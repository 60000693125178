.header-wrap{
    width: 100%;
    height: 72px;
    font-size: 18px;
    position: fixed;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
    .header-content{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header-content-logo{
            width: auto;
            height: 38px;
        }
        // 标题按钮
        .title-btn-wrap{
            display: flex;
            .title-btn-item{
                padding: 0 20px;
                color: #626879;
                cursor: pointer;
            }
            .active{
                color: #396BF0;
            }
        }
        // 按钮
        .header-btn-bar{
            display: flex;
            align-items: center;
            >div{
                border-radius: 16px;
                margin-left: 10px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            >div:first-of-type{
                margin-left: 0;
            }
            .search-btn{
                width: 90px;
                background: #E8EEFE;
                color: #A4B6FF;
                .search-icon{
                    width: 17px;
                    height: 18px;
                    margin-right: 5px;
                }
            }
            .login-btn{
                width: 80px;
                border:1px solid #396bf0;
                color: #396BF0;
            }
            .contact-btn{
                width: 118px;
                color: #fff;
                background: #396BF0;
            }
        }
        // 搜索栏
        .search-wrap{
            width: 1200px;
            height: 120px;
            position: absolute;
            top: 72px;
            padding-top: 48px;
            background: #fff;
            z-index: 9;
            box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
            .search-wrap-close{
                width: 14px;
                height: 14px;
                cursor: pointer;
                background:url('../../assets/homePage/close.png') no-repeat center;
                background-size: 14px 14px;
                position: absolute;
                right: 18px;
                top: 13px;
            }
            .search-wrap-content{
                display: flex;
                align-items: center;
                justify-content: center;
                .search-input{
                    width: 830px;
                    height: 50px;
                    border-radius: 25px 0 0 25px;
                }
                .search-input-btn{
                    width: 100px;
                    height: 50px;
                    border-radius: 0 25px 25px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #396BF0;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
    // 头像
    .avatar-module{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        position: relative;
        .avatar-btn-list{
            min-width: 124px;
            display: none;
            position: absolute;
            background: #ffffff;
            padding: 30px 20px;
            box-shadow: 0 0 10px 0 #B4B3B3;
            border-radius: 4px;
            flex-direction: column;
            justify-content: space-between;
            white-space: nowrap;
            left: -44px;
            top: 36px;
            font-size: 14px;
            position: absolute;
            z-index: 10;
        }
        .avatar-btn-list div{
            line-height: 1;
            margin-bottom: 24px;
        }
        .avatar-btn-list div:last-child{
            margin-bottom: 0;
        }
    }
    .avatar-module:hover .avatar-btn-list{
        display: flex;
    }
}
// 报考咨询
.contact-module-wrap{
    position: fixed;
    right: 40px;
    top: 20%;
    width: 100px;
    // height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contact-btn-wrap{
        width: 100%;
        border: 1px solid #e8eefe;
        box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
        background: #FFF;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .contact-btn-item{
            >div{
                height: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #396BF0;
                flex-grow: 1;
                cursor: pointer;
                position: relative;
                >img{
                    margin-bottom: 4px;
                }
            }
        }
        .QR-code-module{
            border-top: 1px solid #E8EEFE;
        }
        .QR-code-module img:first-of-type{
            width: 50px;
            height: 50px;
        }
        .QR-code-module:hover .QR-code{
            display: block;
        }
        .QR-code{
            width: 200px;
            height: 200px;
            background: #FFF;
            position: absolute;
            right: 100px;
            display: none;
            box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
        }
    }
    .to-top{
        margin-top: 10px;
        width: 100%;
        height: 40px;
        border: 1px solid #e8eefe;
        background: #FFF;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
// 报考模态框
.contact-modal-title{
    text-align: center;
    line-height: 1;
    >div:first-of-type{
        font-size: 22px;
        color: #343537;
    }
    >div:nth-of-type(2){
        font-size: 15px;
        color: #949699;
        margin: 17px 0 40px;
    }
}
.contact-modal-form-wrap{
    .ant-input{
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
    }
    .ant-input-group-addon{
        cursor: pointer;
        border-top-color: transparent;
        border-right-color: transparent;
        background: transparent;
    }
}