.ant-cascader-input.ant-input{
    border: none !important;
}
.ant-cascader-picker{
    border-radius: 16px;
}
.ant-input-affix-wrapper .ant-input:focus {
    border: none;
    border-bottom: 1px solid white !important;
    box-shadow: none;
}
.update-password-form .ant-input-affix-wrapper .ant-input:focus {
    border:1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.chooseBtn {
    width: 140px;
    height:32px;
    border-radius:16px;
    border: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #C8C8C8;
    font-size: 14px;
    padding: 0 10px 0 16px;
    cursor: pointer;
}
.topBg {
    margin: 0 auto;
    width: 1200px;
    height: 68px;
    display: flex;
    align-items: center;
    // background:#FFD663
    border-bottom: 1px solid #E9E9E9;
    .top-bg-txt-wrap{
        align-items: center;
        display: flex;
        height: 26px;
        padding: 0 16px;
        border-left: 4px solid #FFD663;
    }
}
.topFont{
    color:#343537;
    font-size: 18px;
    font-weight: bold;
}