// 题库
.new-exam-index-wrap{
    padding: 0 20px!important;
    display: block!important;
    margin-top: 24px;
    margin-bottom: 10px;
    .ant-select-selection{
        border-radius: 16px;
    }
    // 章节类型
    .section-type-wrap{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        .section-type-item{
            width: 256px;
            box-sizing: border-box;
            height: 114px;
            margin-top: 30px;
            border: 1px solid #D2E7FF;
            border-radius: 6px;
            margin-right: 32px;
            padding: 0 28px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: all .3s;
            box-shadow: 0px 3px 10px 0px rgba(168, 168, 168, 0.07);
            .section-type-item-img{
                width: 66px;
                height: 66px;
                margin-right: 16px;
            }
            .section-type-item-title{
                margin-bottom: 0;
                font-weight: bold;
                font-size: 18px;
                line-height: 1.5;
            }
        }
        .section-type-item:nth-of-type(4n){
            margin-right: 0;
        }
        .section-type-item:nth-of-type(-n+4){
            margin-top: 0;
        }
        .section-type-item:hover{
            border-color: #FFC100;
            box-shadow: 0px 4px 12px 0px rgba(39, 83, 193, 0.12);
        }
    }
}
// 题库列表
/* .new-exam-list-wrap{
    box-sizing: border-box;
    // 不含子
    .new-exam-list-child{
        margin: 0 10px;
        height: 70px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 16px;
        border-bottom: 1px dashed #DDE3E7;
        .title-title{
            color: #333;
        }
        .title-questions{
            color: #949699;
            flex-grow: 1;
            // text-align: center;
            text-indent: 2em;
        }
        .title-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            background: #FFC100;
            border-radius: 20px;
            height: 30px;
            padding: 8px 16px;
            cursor: pointer;
        }
    }
    .new-exam-list-parent-wrap{
        margin-top: 24px;
    }
    // 含子
    .new-exam-list-parent{
        box-sizing: border-box;
        height: 55px;
        background: #F7FAFD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 2px;
        .left-border{
            width: 3px;
            height: 16px;
            background: #FFC100;
        }
        .title-name{
            flex-grow: 1;
            margin-left: 18px;
        }
        .title-icon{
            margin: 0 30px 0 12px;
        }
    }
    .new-exam-list-child{
        .title-name{
            width: 45%;
            flex-shrink: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
// 试题
.new-exam-test-wrap{
    margin: 0 auto;
    width: 1200px;
    .new-exam-test-title-wrap{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 124px;
        margin: 30px 0 20px;
        background: #fff;
        line-height: 1;
        .new-exam-test-title{
            color: #343537;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .new-exam-test-question-count{
            color: #949699;
            font-size: 18px;
        }
    }
    .new-exam-test-paper-wrap{
        background: #fff;
        .questions-module{
            padding: 30px;
            .media-wrap,.image-wrap{
                display: inline;
            }
            .questions-title{
                display: flex;
                margin-bottom: 24px;
                justify-content: space-between;
                img{
                    max-width: 100%;
                }
                .questions-type{
                    color: #FFC100;
                }
                .questions-point{
                    color: #949699;
                }
                .questions-btn{
                    cursor: pointer;
                    flex-shrink: 0;
                    height: 32px;
                    width: 110px;
                    border: 1px solid #DDE0E5;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                }
            }
            .questions-answers{
                .questions-option:hover{
                    background: #F7FAFD;
                }
                .questions-option{
                    margin-bottom: 24px;
                    display: flex;
                    cursor: pointer;
                    .option-circle,.option-square{
                        width: 30px;
                        height: 30px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #B3B7BB;
                        margin-right: 15px;
                        flex-shrink: 0;
                    }
                    .option-circle{
                        border-radius: 50%;
                    }
                    .option-square{
                        border-radius: 4px;
                    }
                    .option-checked{
                        background: #FFC100;
                        border-color: #FFC100;
                    }
                    .option-right{
                        background: #41BF92!important;
                        border:2px solid #41BF92!important;
                        color:#FFFFFF!important;
                    }
                    
                    .option-error{
                        background: #F75B53!important;
                        border:2px solid #F75B53!important;
                        color:#FFFFFF!important;
                    }
                }
                .option-description{
                    margin-top: 2px;
                    img{
                        max-height: 100px;
                        max-width: 100%;
                    }
                }
            }
            .answers-analysis{
                background: #F5FAFF;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                border-radius: 10px;
                font-size: 16px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // .answers-div{
                //     padding: 0 29px 75px;
                //     overflow: hidden;
                // }
                .right-answers{
                    color: #41BF92;
                    margin: 0 0 20px;
                    line-height: 1.5;
                    word-break: break-all;
                    display: flex;
                    .right-answers-title{
                        color: #626368;
                        flex-shrink: 0;
                    }
                    img{
                        max-width: 100%;
                    }
                }
                .answers-text{
                    text-align: left;
                    line-height: 1.5;
                    width: 100%;
                    word-break: break-all;
                    display: flex;
                    .analysis-title{
                        color: #626368;
                        flex-shrink: 0;
                    }
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
} */

.new-exam-list-wrap{
    display: flex;
    flex-direction: column;
    // 不含子
    .new-exam-list-child{
        
        .title-title{
            
        }
        .title-questions{
            
        }
        .title-btn{
            
        }
    }
    .new-exam-list-parent-wrap{
        margin-top: 24px;
    }
    // 含子
    .new-exam-list-parent{
        
        .left-border{
            
        }
        .title-name{
            
        }
        .title-icon{
            
        }
    }
    .new-exam-list-child{
        .title-name{
            
        }
    }
}