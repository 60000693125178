.phone_login_content{
    display: flex;
    background: #ffffff;
    width: 838px;
    margin: 0 auto;
    .agree_box {
        height: 14px;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #8B8B8B;
        margin-top: 49px;
        .remark_text {
            color: #396BF0;
        }
        
    }
    .logo_box {
        margin-bottom: 54px;
    }
    .right_content_box{
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        p{
            font-size: 38px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #332924;
            line-height: 24px;
            height: 40px;
        }
        .welcome_speech{
            font-size:28px;
            font-weight:100;;
            color:rgba(139,139,139,1);
        }
        .tip_text{
            font-size:20px;
            font-weight:100;;
            color:rgba(139,139,139,1);
        }
    }
    .form_box{
        margin-top: 41px;
        width: 100%;
        .ant-input{
            border: none;
            font-family: Source Han Sans CN;
            font-weight: 400;
            border-bottom: 1px solid #EDEDED;
            font-size:19px;
            color:#332A2A;
            padding-left: 0px;
        }
        .ant-input-group-addon{
            background: #ffffff;
            border: none;
            border-bottom: 1px solid #EDEDED;
            font-size:17px;
            font-weight:100;
            cursor: pointer;
        }
        .ant-input:focus{
            border:none;
            border-bottom: 1px solid #EDEDED;
            box-shadow: none;
        }
        .ant-input:hover{
            border:none;
            border-bottom: 1px solid #EDEDED;
            box-shadow: none;
        }
    }
    .password_login_text{
        margin-top: 33px;
        height: 19px;
        font-size: 19px;
        font-family: Source Han Sans CN;
        line-height: 24px;
        color:#332924;
    }
    .orange_hover:hover {
        color:#FFBD30;
    }
    .blue_hover:hover {
        color:#396BF0 !important;
    }


    .footer_btn{
        margin-top: 51px;
        button{
            width:211px;
            height:49px;
            background:rgba(254,222,36,1);
            border-radius:24px;
            border: none;
            outline: none;
            cursor: pointer;
            font-size:20px;
            font-weight:100;;
            color:rgba(51,42,42,1);
        }
    }
    .over_solve_box{
        margin-top: 205px;
        padding-bottom: 30px;
    }
    //注册成功的样式
    .register_success_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .success_tip{
            font-size:34px;
            font-weight:500;
            color:rgba(33,34,58,1);
            span{
                display: inline-block;
                margin-left: 12px;
            }
        }
        p{
            color: #9498A7;
            font-size: 16px;
            margin-top: 22px;
            span{
                color: #FFB904;
            }
        }
    }
}
.tenantCode_box{
    .tip {
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: #8B8B8B;
        margin: 26px 0px 59px 0px;
    }
}

.orange_style {
    .remark_text {
        color:#FFAE02 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #FFAE02 !important;
        border-color: #FFAE02 !important;
    }
    .ant-checkbox-checked::after {
        border: 1px solid #FFAE02 !important;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #FFAE02 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #FFC121!important;
        border-color: #FFC121!important;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner{
        border-color: #FFC121!important;
    }
}