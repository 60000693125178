.phone_login{
    display: flex;
    background: #ffffff;
    .left_box{
        height: 100vh;
        width: 934px;
        position: relative;
        img{
            max-width: 934px;
        }
        .float_logo_img{
            position: absolute;
            top: 32px;
            left: 424px;
        }
    }
}