.container_main{
    height: 100vh;
    width: 100%;
    background: url("../../assets/newImg/Background1@2x.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
}
.content_main{
    // width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon1_father_box{
        height: 110px;
    }
    .title_box{
        font-weight:100;;
        width: 400px;
        color:rgba(139,139,139,1);
        font-size: 26px;
        margin-top: 34px;
        display: flex;
        justify-content: space-between;
        // letter-spacing: 50px;
        span{
            display: inline-block;
        }
    }
    .title_box>span:nth-child(1){
        width: 83px;
        text-align: justify;
        text-justify:distribute;
        span{
            width: 100%;
        }
    }
    .title_box>span:nth-child(2){
        color: #D8D8D8;
    }
    .title_box>span:nth-child(3){
        width: 253px;
        text-align: justify;
        text-justify:distribute;
        span{
            width: 100%;
        }
    }
    .center_box{
        margin-top: 162px;
        button{
            width:205px;
            height:49px;
            background:rgba(254,222,36,1);
            border-radius:24px;
            border: none;
            outline: none;
            cursor: pointer;
            font-size:20px;
            font-weight:100;;
            color:rgba(51,42,42,1);
        }
    }
    .agreement_box{
        display: flex;
        margin-top: 31px;
        font-size: 14px;
        color: #A7B1BA;
        .check_img_box{
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        span{
            color: #FFC121;
            cursor: pointer;
        }
    }
    .footer_btn{
        margin-top: 74px;
        width: 100%;
        display: flex;
        // justify-content: space-around;
        position: relative;
        .footer_left_btn{
            display: inline-block;
        }
        .footer_left_btn:hover{
            .app_export_code{
                display: inline-block;
            }
        }
        .footer_right_btn:hover{
            .officia_account_code{
                display: inline-block;
            }
        }
        .app_export_code{
            width: 262px;
            height: 262px;
            background: #ffffff;
            padding: 20px;
            position: absolute;
            top: -300px;
            left: -40px;
            span{
                position: absolute;
                top: 260px;
                left: 120px;
                display: inline-block;
                border: 20px solid;
                border-color: #ffffff transparent  transparent  transparent ;
            }
        }
        .officia_account_code{
            width: 262px;
            height: 262px;
            background: #ffffff;
            padding: 20px;
            position: absolute;
            top: -300px;
            right: -50px;
            span{
                position: absolute;
                top: 260px;
                left: 110px;
                display: inline-block;
                border: 20px solid;
                border-color: #ffffff transparent  transparent  transparent ;
            }
        }
        button{
            background:rgba(255,255,255,1);
            border: none;
            outline: none;
            height: 54px;
            font-size:16px;
            font-weight:100;
            color: #000000;
        }
    }
    .footer_common_btn button:nth-child(1){
        width: 116px;
        border-radius: 27px 0 0 27px;
        border-right: 1px solid #E8E8E8;
    }
    .footer_common_btn button:nth-child(2){
        width: 49px;
        border-radius: 0 27px 27px 0;
    }
}
.QRCode{
    display: none;
}