// 轮播图
.swiper-module{
    width: 100%;
    height: 430px;
    margin: 0 auto;
    background: #F5FAFF;
    // margin-bottom: 5px;
    .swiper-module-wrap{
        width: 1920px;
        height: 430px;
        margin-left: calc(-960px + 50%);
        .banner-img{
            width: 1920px;
            height: 430px;
        }
        .swiper-button-prev,.swiper-button-next{
            display: none;
            color: #fff;
        }
        .swiper-button-prev:after, .swiper-button-next:after{
            font-size: 35px;
        }
        // 分页器
        .swiper-pagination{
            .swiper-pagination-bullet{
                background: #8C9FE4;
                opacity: 1;
                border-radius: 4px 4px 4px 4px;
            }
            .swiper-pagination-bullet-active{
                background: #fff;
                width: 30px;
            }
        }
    }
    .swiper-module-wrap:hover .swiper-button-prev,.swiper-module-wrap:hover .swiper-button-next{
        display: flex;
    }
}