.exercise-bar-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 66px;
    border-bottom: 1px solid #E9E9E9;
    >div{
        width: 33%;
    }
    .left_style {
        font-size: 16px;
        color: #343537;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .center_style {
        font-style: normal;
        font-size: 14px;
        color: #949698;
        text-align: center;
    }
    .sub_style {
        i {
            font-style: normal;
            font-size: 14px;
            color: #666666;
        }
    }
    .sub_left_style {
        
    }
    .center_text_box{
        position: static;
        justify-content: center;
        flex-grow: 1;
        // left: calc(50% - 150px);
        font-size: 14px;
        color: #949698;
    }
}
.right_text_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    >div{
        cursor: pointer;
    }
    .has_bg_color{
        width: 96px;
        height: 30px;
        background: #FFC121;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .default_btn{
        color: #FFC121;
        background: #FFFFFF;
        margin-right: 24px;
    }
}
.exercise-list-title-wrap{
    display: block;
    width: 100%;
    background: #FFC121;
    .exercise-list-title{
        width: 1200px;
        margin: 0 auto;
        height: 68px;
        color: #FFFFFF;
        line-height: 68px;
        font-size: 18px;
        font-weight: bold;
    }
}
.modular_content_box{
    width: 1200px;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    padding: 32px 38px 24px;
    background: #fff;
    .ant-collapse{
        background-color: #ffffff;
        border: none;
        .ant-collapse-item{
            .ant-collapse-header{
                padding: 0;
                height: 60px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
                .ant-collapse-arrow{
                    position: static;
                }
            }
        }
    }
    .ant-collapse-content{
        border-top: none!important;
    }
    .ant-collapse-content-box {
        padding: 0!important;
        padding-left: 0!important;
        padding-right: 0!important;
        margin-left: 46px!important;
        .exercise-bar-wrap:last-of-type{
            border-bottom: none;
        }
        .exercise-bar-wrap+.ant-collapse{
            border-top: 1px solid #E9E9E9;
        }
    }
    .ant-collapse-header{
        padding-left: 0!important;
    }
    .ant-collapse-item{
        .ant-collapse-item{
            border-bottom: none;;
            .ant-collapse-header{
            }
        }
    }
}