.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes layout-content-start {
  from {
    margin-left: 240px;
  }
  to {
    margin-left: 80px;
  }
}
@keyframes layout-content-end {
  from {
    margin-left: 80px;
  }
  to {
    margin-left: 240px;
  }
}

#components-layout-demo-custom-trigger .trigger {
  /* font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s; */
  position: fixed;
  top: 22px;
  z-index: 10;
  font-size: 18px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;  
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}
/* 菜单动画 */
.normalBox{
  min-height:100vh;
  animation: layout-content-end .2s linear;
  animation-fill-mode: forwards;
}
.layoutContent{
  min-height:100vh;
  animation: layout-content-start .2s linear;
  animation-fill-mode: forwards;
}
/* antd表格样式覆盖 */
.ant-table{
  font-size: 12px !important; 
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  padding: 14px 14px !important; 
}
/* 弹性盒子垂直水平居中 */
.flex_box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-sider-children>img {
  width: 100%;
  height: 60px;
}

.mySider{
  -webkit-flex: 0 0 240px!important;
  flex: 0 0 240px!important;
  max-width: 240px!important;
  min-width: 240px!important;
  width: 240px!important;
}

.dn{
  display: none!important;
}

::-webkit-scrollbar{
  width: 10px!important;
  height: 10px!important;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  /* background: #ffffff; */
}