.modular_card_box{
    zoom: 1;
}
.modular_card_box::after{
    content: "";
    display: block;
    clear: both;
    visibility: hidden
}
.modular_content_box{
    
    span{
        display: inline-block;
        font-size: 25px;
        margin-right: 32px;
    }
    .tabs_tag{
        font-size: 16px;
        margin: 0 13px;
        display: inline-block;
    }
    .active{
        color: #fff;
        background: #F13232;
        padding: 4px 8px;
        border-radius: 4px;
    }
}
.tabs_tag:hover{
    color: #F13232;
    cursor: pointer;
}

.moduleHeader{
    img{
        margin-top: 6px;
    }
    p:last-child{
        color: #9e9e9e;
        margin-right: 12px;
    }
}

.moduleName {
    font-size: 16px;
    font-weight:500;
    color: #343537;
}