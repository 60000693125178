.home-page-wrap{
    width: 100%;
    min-height: calc(100vh - 72px - 200px);
    
    .home-page-content-title{
        height: 75px;
        margin-bottom: 70px;
    }
    // 各模块标题
    .header-main-box{
        width: 1200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .header-title{
            font-size: 42px;
            font-weight: bold;
            color: #484E61;
            text-align: center;
            word-break: break-all;
        }
        .header-subTitle{
            font-size: 18px;
            text-align: center;
            color: #626368;
            word-break: break-all;

        }
    }
    // 更多课程/更多教师
    .more-btn-box{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // margin-bottom: 60px;
        >div{
            background: #396BF0;
            border-radius: 23px;
            width: 200px;
            height: 46px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    }
    .teacher-list-page{
        margin-top: 0!important;
        margin-bottom: 100px;
        text-align: center;
        .ant-select-arrow{
            top: 15px !important;
        }
    }
     // 选择我们
     .choice-us-wrap{
        padding: 70px 0 60px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        .choice-us-card-module{
            width: 1200px;
            display: flex;
            justify-content: space-between;
            .choice-us-card-item{
                width: 280px;
                height: 450px;
                padding: 0 18px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #c5d8ff;
                border-radius: 10px;
                .icon-wrap{
                    width: 100%;
                    height: 212px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .card-item-title{
                    font-size: 26px;
                    font-weight: bold;
                    color: #343537;
                    line-height: 1;
                    margin-bottom: 30px;
                }
                .card-item-desc{
                    font-size: 17px;
                    color: #626879;
                    line-height: 30px;
                }
            }
            .choice-us-card-item:hover{
                border-color: transparent;
                box-shadow: 0px 0px 20px 0px rgba(55,91,187,0.10); 
            }
        }
        .choice-us-line{
            background: #A4B6FF;
            width: 1200px;
            height: 1px;
            margin: 88px 0 100px;
        }
        .content-card-wrap{
            width: 1200px;
            display: flex;
            flex-direction: column;
            .content-card-item{
                width: 1200;
                height: 360px;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                .content-card-item-title,.content-card-item-desc,.content-card-item-btn-wrap{
                    width: 630px;
                }
                .content-card-item-title{
                    font-size: 26px;
                    color: #343537;
                    font-weight: bold;
                    line-height: 1;
                }
                .content-card-item-desc{
                    font-size: 18px;
                    color: #626879;
                    margin: 34px 0 40px;
                    line-height: 1.5;
                }
                .content-card-item-btn-wrap{
                    display: flex;
                    justify-content: center;
                    .content-card-item-btn{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        width: 140px;
                        height: 40px;
                        opacity: 1;
                        font-size: 20px;
                        background: #396bf0;
                        border-radius: 20px;
                        cursor: pointer;
                    }
                }
            }
            .content-card-item:first-of-type{
                margin-top: 100px;
                height: 456px;
            }
            .content-card-item:nth-of-type(2){
                align-items: flex-end;
            }
        }
    }
    // 选课程tab样式
    .hot-course-content{
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        margin: 20px auto;
        .hot-course-content-item{
            position: relative;
            width: 285px;
            height: 270px;
            margin-right: 20px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
            cursor: pointer;
            .hot-course-content-img{
                width: 285px;
                height: 163px;
                background-size: 100% 100%;
                flex-shrink: 0;
            }
            .hot-course-content-text{
                padding: 16px 12px;
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: space-between;
                .hot-course-content-title{
                    line-height: 18px;
                    font-size: 16px;
                }
                .hot-course-content-price{
                    line-height: 1;
                    font-size: 18px;
                    color: #F84257;
                    text-align: right;
                }
            }
        }
        .hot-course-content-item:nth-of-type(4n){
            margin-right: 0;
        }
    }
    // 关于我们
    .about-us-wrap{
        padding-bottom: 120px;
        background: #F5FAFF url('../../assets/homePage/org-intro-bg.png') no-repeat;
        .about-us-title{
            width: 1100px;
            padding: 144px 0 125px;
            margin: 0 auto;
            line-height: 1;
            color: #fff;
            font-size: 48px;
            font-weight: bold;
            opacity: 0.9;
        }
        .about-us-org-intro{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 1200px;
            margin: 0 auto;
            padding: 40px 100px 80px;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
            .org-intro-desc{
                margin-top: 40px;
                word-break: break-all;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    // 选课程
    .course-select-params-wrap{
        margin: 30px auto;
        padding: 20px 20px 10px;
        width: 1200px;
        background: #fff;
        border-radius: 8px;
        .course-select-params-item-bar{
            font-size: 14px;
            color: #626368;
            display: flex;
            margin-bottom: 10px;
            height: 30px;
            overflow: hidden;
            .course-select-params-item-bar-title{
                height: 30px;
                line-height: 30px;
                margin-right: 20px;
            }
            .course-select-params-item-bar-title,.course-select-params-item-bar-btn{
                flex-shrink: 0;
            }
            .course-select-params-item-bar-options-module{
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
                .options-item{
                    line-height: 1;
                    padding: 8px 0px;
                    margin: 0 20px;
                    color: #343537;
                    height: 30px;
                    display: flex;
                    line-height: 1;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
                .active{
                    padding: 8px 20px;
                    margin: 0 0 10px;
                    color: #396BF0;
                    background: #E8EEFE;
                }
            }
        }
    }
    .course-list-page{
        margin-top: 0!important;
        margin-bottom: 100px;
        text-align: center;
    }
    // 课程详情
    .course-detail-wrap{
        background: url('../../assets/homePage/course-detail-bg.png') no-repeat;
        .bread-crumb{
            padding-top: 20px;
            width: 1200px;
            margin: 0 auto;
            color: #fff;
            .ant-breadcrumb-separator,.ant-breadcrumb-link{
                color: #fff;
            }
        }
        .course-detail-base-info-wrap{
            width: 1200px;
            margin: 80px auto 30px;
            position: relative;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 0px rgba(55,91,187,0.10); 
            background: #fff;
            position: relative;
            padding: 30px 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .top-info{
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                img{
                    width: 285px;
                    height: 163px;
                    border-radius: 10px;
                }
                .top-info-txt-wrap{
                    display: flex;
                    justify-content: space-between;
                    height: 163px;
                    margin-left: 30px;
                    flex-grow: 1;
                    flex-direction: column;
                    .top-info-title{
                        font-size: 24px;
                        color: #343537;
                        line-height: 28px;
                    }
                    .price-bar{
                        display: flex;
                        justify-content: space-between;
                        .price-text{
                            font-size: 30px;
                            color: #F84257;
                            line-height: 35px;
                            font-weight: bold;
                        }
                        .top-info-contact-btn{
                            width: 120px;
                            height: 42px;
                            background: #396bf0;
                            border-radius: 21px;
                            color: #fff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                }
            }
            // 试看列表
            .free-audition-list-wrap{
                width: 100%;
                padding-top: 30px;
                margin-top: 30px;
                border-top: 1px solid #E9E9E9;
                .free-audition-list{
                    box-sizing: border-box;
                    overflow-x: auto;
                    display: flex;
                    .free-audition-list-item{
                        cursor: pointer;
                        width: 260px;
                        height: 148px;
                        margin-right: 20px;
                        border-radius: 10px;
                        flex-shrink: 0;
                        position: relative;
                        overflow: hidden;
                        position: relative;
                        background-size: 100%!important;
                        color: #fff;
                        .free-audition-list-item-tag{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 50px;
                            height: 28px;
                            border-radius: 8px 0px 8px 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1;
                            font-size: 14px;
                            z-index: 1;
                        }
                        .free-txt{
                            background: linear-gradient(268deg, #FD4E0E 0%, #FE8C53 100%);
                        }
                        .pay-txt{
                            background: linear-gradient(268deg, #FF9900 0%, #FFC224 100%);
                        }
                        .free-audition-list-item-mask{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: #000;
                            opacity: 0.5;
                        }
                        .free-audition-list-item-title{
                            position: absolute;
                            left: 0;
                            bottom: 10px;
                            padding: 0 12px;
                            font-size: 18px;
                            line-height: 28px;
                        }
                        .free-audition-list-item-pay{
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            line-height: 1;
                            font-size: 18px;
                        }
                    }
                    .free-audition-list-item:last-of-type{
                        margin-right: 0;
                    }
                }
            }
        }
        // 课程介绍tab
        .course-detail-tab{
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            margin-bottom: 95px;
            border-radius: 8px;
            .ant-tabs-tab:first-of-type{
                margin-left: 100px;
            }
            .ant-tabs-tab{
                padding: 0;
                height: 75px;
                line-height: 75px;
                font-size: 20px;
            }
            .ant-tabs-nav .ant-tabs-tab-active{
                color: #396BF0;
            }
            .ant-tabs-ink-bar{
                background: #396BF0;
            }
            // 课程介绍
            .course-intro-wrap{
                padding: 14px 90px 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    margin-bottom: 40px;
                }
                .course-intro-info{
                    margin-bottom: 30px;
                    width: 100%;
                    background: #F9F9F9;
                    border-radius: 10px;
                    padding: 0 32px;
                    .course-intro-info-item{
                        display: flex;
                        height: 64px;
                        align-items: center;
                        font-size: 16px;
                        .course-intro-info-item-title{
                            width: 96px;
                            flex-shrink: 0;
                            color: #999;
                        }
                        .course-intro-info-item-desc{
                            flex-grow: 1;
                            border-bottom: 1px solid #E9E9E9;
                            color: #332924;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            overflow:hidden;
                            text-overflow: ellipsis;
                            line-height: 19px;
                        }
                    }
                    .course-intro-info-item:last-of-type .course-intro-info-item-desc{
                        border-bottom: none;
                    }
                }
                .course-intro-desc{
                    width: 100%;
                    img{
                        max-width: 100%;
                    }
                }
                .area-name{
                    font-size: 20px;
                    color: #343537;
                    margin-right: 40px;
                }
                .ant-select-selection{
                    border: none;
                    box-shadow: none;
                    font-size: 20px;
                    .ant-select-selection-selected-value{
                        color: #396BF0;
                    }
                }
            }
            .course-list-wrap{
                width: 100%;
                padding: 0 150px;
                font-size: 20px;
                .course-list-item{
                    padding: 12px 0;
                    .ant-collapse-header{
                        padding-left: 0;
                        .ant-collapse-arrow{
                            left: initial;
                            right: 0;
                        }
                    }
                    .ant-collapse-content-box{
                        padding: 0;
                    }
                    >.ant-collapse-header{
                        font-weight: bold;
                        font-size: 20px;
                    }
                    .course-list-charter{
                        font-size: 20px;
                        border-bottom: none;
                        >.ant-collapse-header{
                            margin-left: 20px;
                        }
                        .course-list-section{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 20px;
                            color: #595656;
                            margin-left: 20px;
                            padding: 12px 0;
                            .live-icon{
                                width: 36px;
                                height: 20px;
                                border-radius: 2px;
                                border: 1px solid #396BF0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #396BF0;
                                line-height: 1;
                                font-size: 14px;
                                flex-shrink: 0;
                            }
                            .course-list-section-title{
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                flex-grow: 1;
                                padding-left: 6px;
                            }
                            .free-btn{
                                width: 68px;
                                height: 32px;
                                border-radius: 18px;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #396BF0;
                                border: 1px solid #396BF0;
                            }
                            .icon-lock{
                                width: 40px;
                                height: 40px;
                                background-size: 100%!important;
                            }
                            .free-btn,.icon-lock{
                                cursor: pointer;
                                margin-left: 20px;
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
            // 课程资料
            .file-module-item-wrap{
                margin: 40px auto;
                width: 720px;
                height: 50px;
                background: #396BF0;
                border-radius: 25px;
                color: #fff;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .file-module-item-icon{
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
        }
        // 外部产品——录播
        .class-info-wrap {
            padding: 0 120px;
            width: 100%;
            .class-info-wrap-select{
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
                .class-info-wrap-select-item{
                    font-size: 17px;
                    color: #343537;
                }
            }
            .class-info-item {
                .course-info-item-coursename {
                    font-size: 20px;
                    font-weight: bold;
                    color: #343537;
                    max-width: 670px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .course-list-item-charter-wrap {
                    padding-left: 24px;
                    .course-list-item-charter-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                        margin-top: 20px;
                        .course-list-item-chartername {
                            font-size: 20px;
                            font-weight: 400;
                            color: #343537;
                            max-width: 570px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .free-study-wrap {
                            width: 115px;
                            height: 36px;
                            border: 1px solid #396BF0;
                            border-radius: 18px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            cursor: pointer;
                            .img-play {
                                width: 18px;
                                height: 18px;
                                margin-bottom: 0px;
                                margin-left: 14px;
                            }
                            .free {
                                font-size: 16px;
                                font-weight: 400;
                                color: #396BF0;
                                margin-left: 4px;
                            }
                        }
                    }
                }
            }
            .course-list-item-intro {
                font-size: 16px;
                font-weight: 400;
                color: #949699;
                margin-top: 16px;
                margin-left: 24px;
            }
        }
        //直播
        .live-info-wrap {
            padding: 0 120px;
            width: 100%;
            .live-info-item-coursename {
                margin-top: 18px;
                margin-bottom: 12px;
                font-size: 20px;
                font-weight: 400;
                color: #343537;
                max-width: 622px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .live-info-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #F1F1F1;
                .live-info-item-course-wrap {
                    display: flex;
                    flex-direction: column;
                    margin-left: 24px;
                    .live-info-item-coursename {
                        margin-top: 18px;
                        margin-bottom: 12px;
                        font-size: 20px;
                        font-weight: 400;
                        color: #343537;
                        max-width: 622px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .live-info-item-time {
                        margin-bottom: 20px;
                        font-size: 20px;
                        font-weight: 400;
                        color: #949699;
                    }
                }
                .free-study-wrap {
                    width: 115px;
                    height: 36px;
                    border: 1px solid #396BF0;
                    border-radius: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 4px;
                    cursor: pointer;
                    .img-play {
                        width: 18px;
                        height: 18px;
                        margin-bottom: 0px;
                        margin-left: 14px;
                    }
                    .free {
                        font-size: 16px;
                        font-weight: 400;
                        color: #396BF0;
                        margin-left: 4px;
                    }
                }
            }
        }
        //套餐
        .package-info-wrap {
            padding: 0 120px;
            width: 100%;
            .package-info-live-wrap {
                .package-info-live-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 50px;
                    background: #F5FAFF;
                    border: 1px solid #ECF5FE;
                    border-radius: 10px;
                    .img {
                        width: 32px;
                        height: 32px;
                        margin-right: 6px;
                        margin-bottom: 0;
                    }
                    .name {
                        font-size: 22px;
                        font-weight: bold;
                        color: #343537;
                    }
                }
                .package-info-live-item-wrap {
                    padding-top: 24px;
                    .new-up-icon {
                        width: 32px;
                    }
                    .package-info-live-coursename-wrap {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        margin-bottom: 24px;
                        .package-info-live-coursename {
                            font-size: 20px;
                            font-weight: bold;
                            color: #343537;
                            max-width: 622px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .zhibo-course {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        margin-left: 24px;
                        margin-top: 26px;
                    }
                    .live-info-item-coursename {
                        font-size: 20px;
                        font-weight: 400;
                        color: #343537;
                        max-width: 622px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .package-info-live-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: 24px;
                        border-bottom: 1px solid #E8EEFE;
                        .live-info-item-course-wrap {
                            display: flex;
                            flex-direction: column;
                            .live-info-item-coursename {
                                margin-top: 20px;
                                margin-bottom: 10px;
                                font-size: 20px;
                                font-weight: 400;
                                color: #343537;
                                max-width: 622px;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                            .live-info-item-time {
                                margin-bottom: 18px;
                                font-size: 20px;
                                font-weight: 400;
                                color: #949699;
                            }
                        }
                        .free-study-wrap {
                            width: 115px;
                            height: 36px;
                            border: 1px solid #396BF0;
                            border-radius: 18px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-top: 4px;
                            cursor: pointer;
                            .img-play {
                                width: 18px;
                                height: 18px;
                                margin-bottom: 0px;
                                margin-left: 14px;
                            }
                            .free {
                                font-size: 16px;
                                font-weight: 400;
                                color: #396BF0;
                                margin-left: 4px;
                            }
                        }
                    }
                    .line {
                        height: 1px;
                        background: #E8EEFE;
                        margin-top: 24px;
                    }
                }
            }
            .package-info-class-wrap {
                margin-top: 40px;
                .package-info-class-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 50px;
                    background: #F5FAFF;
                    border: 1px solid #ECF5FE;
                    border-radius: 10px;
                    .img {
                        width: 32px;
                        height: 32px;
                        margin-right: 6px;
                        margin-bottom: 0;
                    }
                    .name {
                        font-size: 22px;
                        font-weight: bold;
                        color: #343537;
                    }
                }
                .package-info-class-item-wrap {
                    padding-top: 24px;
                    .package-info-class-coursename-wrap {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        margin-bottom: 24px;
                        .package-info-class-coursename {
                            font-size: 20px;
                            font-weight: bold;
                            color: #343537;
                            max-width: 622px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .new-up-icon {
                            width: 32px;
                        }
                    }
                    .package-info-class-select{
                        display: flex;
                        justify-content: center;
                        margin-bottom: 30px;
                        .package-info-class-select-item{
                            font-size: 17px;
                            color: #343537;
                        }
                    }
                    .package-info-class-item {
                        .class-info-item {
                            padding-left: 30px;
                            .course-wrap {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-top: 14px;
                                margin-bottom: 14px;
                                .free-study-wrap {
                                    width: 115px;
                                        height: 36px;
                                        border: 1px solid #396BF0;
                                        border-radius: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        margin-top: 6px;
                                        cursor: pointer;
                                        .img-play {
                                            width: 18px;
                                            height: 18px;
                                            margin-bottom: 0px;
                                            margin-left: 14px;
                                        }
                                        .free {
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #396BF0;
                                            margin-left: 4px;
                                        }
                                }
                            }
                            .course-info-item-coursename {
                                font-size: 20px;
                                font-weight: 400;
                                color: #343537;
                                max-width: 670px;
                                text-overflow: -o-ellipsis-lastline;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                            .course-list-item-charter-wrap {
                                padding-left: 30px;
                                .course-list-item-charter-item {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 20px;
                                    margin-top: 20px;
                                    .course-list-item-chartername {
                                        font-size: 20px;
                                        font-weight: 400;
                                        color: #343537;
                                        max-width: 570px;
                                        text-overflow: -o-ellipsis-lastline;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                    }
                                    .free-study-wrap {
                                        width: 115px;
                                        height: 36px;
                                        border: 1px solid #396BF0;
                                        border-radius: 18px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        cursor: pointer;
                                        .img-play {
                                            width: 18px;
                                            height: 18px;
                                            margin-bottom: 0px;
                                            margin-left: 14px;
                                        }
                                        .free {
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: #396BF0;
                                            margin-left: 4px;
                                        }
                                    }
                                }
                                .line {
                                    margin-top: 30px;
                                    height: 1px;
                                    background: #E8EEFE;
                                }
                            }
                        }
                        .course-list-item-intro {
                            font-size: 16px;
                            font-weight: 400;
                            color: #949699;
                            margin-top: 10px;
                            margin-bottom: 20px;
                        }
                    }
                    .line {
                        height: 1px;
                        background: #E8EEFE;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}
.area-selection{
    width: 300px;
    left: calc(50% - 150px)!important;
    .ant-select-dropdown-menu-item{
        text-align: center;
    }
    .ant-select-dropdown-menu-item-selected{
        color: #396BF0;
    }
}
.ant-select-arrow{
    top: 36%;
}
.ant-select-selection-selected-value {
    font-size: 17px;
}

.agreement-list-wrap{
    width: 1200px;
    margin: 25px auto;
    background: #fff;
    border-radius: 10px;
    .agreement-list-title{
        font-size: 16px;
        font-weight: bold;
        height: 55px;
        border-bottom: 1px solid #E9E9E9;
        padding-left: 20px;
        display: flex;
        align-items: center;
    }
    .agreement-list-content{
        padding: 0 24px;
        .agreement-list-content-item{
            padding: 24px 0;
            border-bottom: 1px solid #EEE;
            display: flex;
            align-items: center;
            .agreement-list-content-item-msg{
                flex-grow: 1;
                height: 78px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 14px;
                margin-left: 14px;
                color: #626368;
                >div:first-of-type{
                    font-weight: bold;
                    font-size: 16px;
                    color: #343537;
                }
                span{
                    display: inline-block;
                    width: 70px;
                    color: #949699;
                    text-align-last:justify
                }
            }
            .agreement-list-content-item-btn-wrap{
                flex-shrink: 0;
                display: flex;
                div{
                    width: 60px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                    margin-left: 20px;
                    color: #FFC100;
                    border: 1px solid #FFC100;
                    cursor: pointer;
                }
                div:nth-of-type(2){
                    color: white;
                    background: #FFC100;
                    border: 1px solid transparent;
                }
            }
        }
        .agreement-list-content-item:last-of-type{
            border-bottom: none;
        }
    }
}
// 课程免费试看标识
.free-live-icon{
    position: absolute;
    left: 0;
    top: 0;
    width: 76px;
    height: 28px;
    background: linear-gradient(268deg, #FD4E0E 0%, #FE8C53 100%);
    border-radius: 8px 0px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1;
    color: #fff;
}
// 中转页
.transit-page-wrap{
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background: #F8F8FA;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    .transit-page-logo-wrap{
        height: 38px;
        width: 100%;
        background-size:auto 100%!important;
        background-repeat:no-repeat;
    }
    .transit-page-img{
        width: 500px;
        height: 400px;
        margin-top: 148px;
        background: url('../../assets/homePage/img_quesheng@2x.png') no-repeat;
        background-size: cover;
    }
    .transit-page-tips{
        color: #343537;
        font-size: 20px;
        line-height: 36px;
        .router-link{
        color: #4677F8;
        cursor: pointer;
        }
    }
}